import React from "react";
import "@flixbus/honeycomb/dist/css/honeycomb.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Header,
  HeaderBrand,
  Heading,
  MainWrapper,
  PageContainer,
  ThemeWrapper,
  themeKamil,
  themeDefault,
  themeNeptune,
  Text,
  Grid,
  GridCol,
  Input,
  FormRow,
  Box,
  Button,
  PhoneInput,
  Popup,
  PopupSection,
  Fineprint,
  Link,
  Checkbox,
} from "@flixbus/honeycomb-react";
import {
  Icon,
  IconCheckmarkStrongSolid,
  IconCrossedSolid,
} from "@flixbus/honeycomb-icons-react";
import { useTranslation } from "react-i18next";
import {
  flagAddOn,
  getTheme,
  getDefaultCountry,
  checkEmail,
  checkPhone,
  getDefaultPhonePrefix,
  useProgressiveImage,
  useWindowDimensions,
  getFooterImage
} from "./components/helpers/functions";
import {  callingCodes } from "./components/helpers/constants";
import axios from "axios";
import "./App.css";

declare global {
  interface Window {
    UC_UI?: any
  }
}

function App() {
  const query = new URLSearchParams(window.location.search);
  const language = query.get("language") ? query.get("language")!.toLowerCase() : "en";
  const inputBrand = query.get("brand") ? query.get("brand")!.toLowerCase() : "flixbus";
  const [phoneError, setPhoneError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [captchaError, setCaptchaError] = React.useState(false);
  const [isConsentValid, setConsentValid] = React.useState<any>(undefined);
  const [isConsentChecked, setConsentChecked] = React.useState(()=> (language==="pt_br" || language==="tr") ? false : true);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [reCaptcha, setReCaptcha] = React.useState<String | null>(null);
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const [popupActive, setPopupActive] = React.useState(false);
  const [consentPopupActive] = React.useState(query.get("success") ? true : false);
  const [errorPopupActive, setErrorPopupActive] = React.useState(false);
  const { width } = useWindowDimensions();
  const themeAssets = getTheme(language, inputBrand)
  const brand = themeAssets.brand;
  const theme = themeAssets.theme;
  const backgroundImage = themeAssets.backgroundImage;
  const brandLogo = themeAssets.brandLogo;
  const country = query.get("country") ? query.get("country")!.toLowerCase() : getDefaultCountry(language, brand);
  const loaded = useProgressiveImage(backgroundImage, width);
  const isMobile = width <= 768;
  const footerImage = getFooterImage(language, brand, isMobile)

  async function handleSubmit(query: URLSearchParams) {
    if (reCaptcha) {
      setLoading(true);
      setEmailError("");
      setPhoneError("");
      if (checkEmail(email)) {
        if (checkPhone(phoneNumber)) {
          if (isConsentChecked){
          axios
            .post(`${process.env.REACT_APP_NEW_CUSTOMER_DISCOUNT_URL!}/v1/customer/voucher`, {
              email: email,
              phone: phoneNumber,
              utm_medium: query.get("utm_medium"),
              utm_source: query.get("utm_source"),
              utm_campaign: query.get("utm_campaign"),
              utm_content: query.get("utm_content"),
              utm_term: query.get("utm_term"),
              wt_mc: query.get("wt_cc"),
              refer: query.get("refer") ? true : false,
              country: query.get("country"),
              language: language,
              recaptcha_token: reCaptcha,
            })
            .then(function (response) {
              setLoading(false);
              if (response.status === 200) {
                setPopupActive(true);
              } else {
                setErrorPopupActive(true);
              }
            })
            .catch((err) => {
              console.log(err);
              setErrorPopupActive(true);
            });
        } else {
          setConsentValid(false)
          setLoading(false);
        }
      } else {
          setPhoneError(t(brand + ".error_phone"));
          setLoading(false);
        }
      } else {
        setEmailError(t(brand + ".error_email"));
        setLoading(false);
      }
    } else {
      setCaptchaError(true);
    }
  }

  function getStyles() {
    if (width > 480) {
      return {
        backgroundImage: `url(${loaded || backgroundImage})`,
        backgroundColor: "grey",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        margin: "60px 0px 0px 0px",
        padding: "4% 0px 4% 0px",
      };
    } else {
      return {
        backgroundColor: "#f7f7f7",
        margin: "60px 0px 0px 0px",
        padding: "20px 0px 20px 0px",
      };
    }
  }

  function handleCookieSettings(){
    window.UC_UI.showSecondLayer(); 
    window.UC_UI.updateLanguage(language);
  }

  return (
      <ThemeWrapper id="theme-wrapper" theme={theme} themes={{ default:themeDefault, kamil: themeKamil, neptune: themeNeptune }}>
       <MainWrapper>
        <div className="background-image" style={getStyles()}>
          <PageContainer extraClasses="page-container">
          <Header fixed={true}>
            <HeaderBrand
              alt={brandLogo.alt}
              href={t(brand + ".homepage")}
              src={brandLogo.src}
              width={brandLogo.width}
              height={brandLogo.height}
            />
          </Header>
            <Grid justify="left">
              <GridCol key="grid-container" size={12} sm={12} md={7}>
                <Box extraClasses="box-form">
                  <Heading id="form-heading" extraClasses="form-heading" size={1}>
                    {t(brand + ".heading", {
                      discount: query.get("refer") ? "20%" : "20%",
                    })}
                  </Heading>
                  <Text>
                    <b>
                      {t(brand + ".description.part1", {
                        discount: query.get("refer") ? "20%" : "20%",
                      })}
                    </b>{" "}
                    {t(brand + ".description.part2")}
                  </Text>
                  <FormRow>
                    <Fineprint>{t(brand + ".description.part3")}</Fineprint>
                  </FormRow>
                  <form>
                    <FormRow>
                      <Input
                        name="email"
                        id="email"
                        label={t(brand + ".email")}
                        aria-label="email"
                        type="email"
                        valid={emailError ? false : undefined}
                        onChange={(e) => setEmail(e.target.value)}
                        infoError={emailError}
                      />
                    </FormRow>
                    <FormRow>
                      <PhoneInput
                        callingCodes={callingCodes}
                        id="phone"
                        label={`${t(brand + ".cellphone")}`}
                        info={phoneError ? phoneError : ""}
                        selectLabel="Select your country"
                        selectAddOn={flagAddOn}
                        selectAddOnSm
                        autoComplete="tel"
                        valid={phoneError ? false : undefined}
                        value={getDefaultPhonePrefix(brand, country)}
                        onBlur={(e) => {
                          setPhoneNumber(e.target.value ? e.target.value : "+");
                          setPhoneError("");
                        }}
                        />
                      </FormRow>
                    {(language === "tr" || language === "pt_br") && 
                        <FormRow>
                        <Checkbox 
                        label={<Fineprint>
                        {t(brand + ".consent_checkbox.label_part_1")} <Link href={t(brand + ".privacy_policy_link")}>{t(brand + ".privacy_policy")}</Link> {t(brand + ".consent_checkbox.label_part_2")} 
                        {" "}<Link href={t(brand + ".general_terms_business_and_booking_link")}>{t(brand + ".general_terms_business_and_booking")}</Link> {t(brand + ".consent_checkbox.label_part_3")}
                      </Fineprint>}
                        infoError={t(brand + ".consent_checkbox.error")} 
                        valid={isConsentChecked ? true : isConsentValid}
                        value="consent-checkbox"
                        id="consent-checkbox"
                        onChange={(e) => setConsentChecked(e.target.checked)}
                        small
                        />
                      </FormRow>}     
                    <FormRow>
                      <Fineprint id="consent-disclaimer">
                        {t(brand + ".opt_in")}
                      </Fineprint>
                    </FormRow>
                    <FormRow>
                      <ReCAPTCHA
                        sitekey="6Lei6eEcAAAAAP5kRapQ8G-5Dk6vUfK534ZXR7yv"
                        hl={language}
                        onChange={(e) => {
                          setReCaptcha(e);
                          setCaptchaError(false);
                        }}
                      />

                      {captchaError ? (
                        <Fineprint style={{ color: "var(--hcr-input-error-info-color)" }}>
                          {t(brand + ".error_captcha")}
                        </Fineprint>
                      ) : null}
                    </FormRow>
                    <Button
                      id="code-button"
                      appearance="primary"
                      loading={loading}
                      disabled={loading}
                      onClick={() => handleSubmit(query)}
                    >
                      {t(brand + ".cta")}
                    </Button>
                  </form>
                  <Popup id="popup" active={popupActive} onOverlayClick={() => setPopupActive(false)}>
                    <PopupSection type="icon">
                      <Icon
                        style={{ fill: "var(--flix-success-color)" }}
                        InlineIcon={IconCheckmarkStrongSolid}
                      />
                    </PopupSection>
                    <PopupSection type="title" id="popup-title">
                      {t(brand + ".thankyou.heading")}
                    </PopupSection>
                    <PopupSection type="content">{t(brand + ".thankyou.text")}</PopupSection>
                    <PopupSection type="actions">
                      <Button
                        appearance={"primary"}
                        onClick={() => {
                          setPopupActive(false);
                        }}
                      >
                        {t(brand + ".close")}
                      </Button>
                    </PopupSection>
                  </Popup>
                  {/* Popup for double opt in success */}
                  <Popup
                    id="popup-consent"
                    active={consentPopupActive}
                    onOverlayClick={() => window.location.assign(t(brand + ".homepage"))}
                  >
                    <PopupSection type="icon">
                      <Icon
                        style={{ fill: "var(--flix-success-color)" }}
                        InlineIcon={IconCheckmarkStrongSolid}
                      />
                    </PopupSection>
                    <PopupSection type="title" id="popup-title-consent">
                      {t(brand + ".consent.heading")}
                    </PopupSection>
                    <PopupSection type="content">{t(brand + ".consent.text")}</PopupSection>
                    <PopupSection type="actions">
                      <Button
                        appearance={"primary"}
                        onClick={() => {
                          window.location.assign(t(brand + ".homepage"));
                        }}
                      >
                        {t(brand + ".close")}
                      </Button>
                    </PopupSection>
                  </Popup>
                  {/* Popup for errors */}
                  <Popup id="popup-error" active={errorPopupActive} onOverlayClick={() => window.location.reload()}>
                    <PopupSection type="icon">
                      <Icon style={{ fill: "var(--flix-danger-color)" }} InlineIcon={IconCrossedSolid}/>
                    </PopupSection>
                    <PopupSection type="title" id="popup-title-error">
                      {t(brand + ".error.heading")}
                    </PopupSection>
                    <PopupSection type="content">{t(brand + ".error.text")}</PopupSection>
                    <PopupSection type="actions">
                      <Button appearance={"primary"} onClick={() => window.location.reload()}>
                        {t(brand + ".close")}
                      </Button>
                    </PopupSection>
                  </Popup>
                </Box>
              </GridCol>
            </Grid>
          </PageContainer>
        </div>
        <PageContainer style={{ padding: "21px" }}>
          <Fineprint>
            {t(brand + ".disclaimer")} <Link href={t(brand + ".privacy_policy_link")}>{t(brand + ".privacy_policy")}</Link>.<br></br>
            <Link href={t(brand + ".imprint_link")}>{t(brand + ".imprint")}</Link> |{" "}
            <Link href="#" onClick = {() => handleCookieSettings()}>{t(brand + ".cookie_settings")}</Link>
          </Fineprint>
        </PageContainer>
        <footer>
          <img
            style={{
              width: "100%",
              bottom: "0",
              maxWidth: "100%",
              verticalAlign: "middle",
              overflow: "auto",
            }}
            alt=""
            src= {footerImage}
          />
        </footer>
      </MainWrapper>
    </ThemeWrapper>
  );
}

export default App;
